import React from "react";
import {Link, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import ScrollToTop from "react-scroll-up";

import {fetchUser} from "../.././redux/actions/user";

import HeaderCart from "./HeaderCart";
import HeaderModal from "./HeaderModal";
import HeaderTop from "./HeaderTop";

import {DOMEN} from "../.././api";

const Header = React.memo(() => {
    const dispatch = useDispatch();

    const [visibleButtonCart, setVisibleButtonCart] = React.useState(false);

    const cartItems = useSelector(({cart}) => cart.cart);
    const {user, isLoaded, isLogin} = useSelector(({user}) => user);

    React.useEffect(() => {
        dispatch(fetchUser());

        window.addEventListener("scroll", () => {
            if (Math.floor(window.pageYOffset) > 200) {
                setVisibleButtonCart(true);
            } else {
                setVisibleButtonCart(false);
            }
        });
    }, []);

    return (
        <>
            {/* <Link
                to="/cart"
                className={`header-cart-button ${
                    visibleButtonCart && window.location.pathname !== "/cart"
                        ? ""
                        : "header-cart-button_disabled"
                }`}
            >
                <svg
                    width="28"
                    height="28"
                    viewBox="0 0 39 40"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill="#3A38DC"
                        d="M35.7632 22.6641L38.6147 8.75781H10.5132L9.53662 4.13281L1.06787 0L0.333496 1.46094L8.06787 5.19531L11.9741 23.8516H34.5522C34.5522 23.8516 36.7788 25.25 35.9194 27.0156C35.5366 27.5625 34.9429 28.2344 33.8804 28.2344H11.4116V29.8438H33.8882C35.4351 29.8438 36.7397 29.0312 37.4741 27.7344C38.9116 25.0469 35.7632 22.6641 35.7632 22.6641ZM13.3647 22.2188L10.8413 10.375H36.6616L34.2163 22.2188H13.3647Z"
                    />
                    <path
                        fill="#3A38DC"
                        d="M15.5784 32.9766C13.8674 32.9766 12.4846 34.3516 12.4846 36.0547C12.4846 37.7578 13.8674 39.1328 15.5784 39.1328C17.2893 39.1328 18.6721 37.7578 18.6721 36.0547C18.6721 34.3516 17.2893 32.9766 15.5784 32.9766ZM15.5784 37.5156C14.7659 37.5156 14.1096 36.8672 14.1096 36.0547C14.1096 35.2422 14.7581 34.5937 15.5784 34.5937C16.3987 34.5937 17.0471 35.2422 17.0471 36.0547C17.0471 36.8672 16.3909 37.5156 15.5784 37.5156Z"
                    />
                    <path
                        fill="#3A38DC"
                        d="M32.1956 32.9766C30.4846 32.9766 29.1018 34.3516 29.1018 36.0547C29.1018 37.7578 30.4846 39.1406 32.1956 39.1406C33.9065 39.1406 35.3753 37.7656 35.2893 36.0625C35.2893 34.3594 33.9065 32.9766 32.1956 32.9766ZM33.6643 36.0625C33.6643 36.875 33.0159 37.5234 32.1956 37.5234C31.3752 37.5234 30.7268 36.875 30.7268 36.0625C30.7268 35.25 31.3752 34.6016 32.1956 34.6016C33.0159 34.6016 33.7424 35.25 33.6643 36.0625Z"
                    />
                </svg>

                <span className="header-cart-button__number">
                    {Object.keys(cartItems).length}
                </span>
            </Link> */}

            {/* <ScrollToTop
                showUnder={500}
                duration={1000}
                style={{zIndex: "1000"}}
            >
                <div className="topup">
                    <svg
                        width="52"
                        height="52"
                        viewBox="0 0 52 52"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M51 26C51 12.1929 39.8071 1 26 1C12.1929 1 1 12.1929 1 26C1 39.8071 12.1929 51 26 51C39.8071 51 51 39.8071 51 26Z"
                            fill="white"
                            stroke="#3A38DC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M36 26L26 16L16 26"
                            stroke="#3A38DC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M26 36V16"
                            stroke="#3A38DC"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>
			</ScrollToTop> */}

            <header className="header">
                <div className="container">
                    <HeaderTop />

                    <div className="header-wrapper">
                        <Link to="/">
                            <img
                                src={`${DOMEN}/public/storage/all/logo.svg`}
                                alt="MASTER Vision"
                                className="header-logo__img"
                            />
                        </Link>

                        <nav className="header-nav">
                            <Link
                                to="/shop"
                                className="btn-regular_color header__btn"
                            >
                                Магазин курсов
                            </Link>

                            <div className="header-menu-hidden">
                                <NavLink
                                    to="/timetable"
                                    className={`header__link`}
                                    activeClassName={`header__link_active`}
                                >
                                    Расписание
                                </NavLink>
                                <NavLink
                                    to="/teachers"
                                    className={`header__link`}
                                    activeClassName={`header__link_active`}
                                >
                                    Спикеры
                                </NavLink>
                                <NavLink
                                    to="/magazine"
                                    className={`header__link`}
                                    activeClassName={`header__link_active`}
                                >
                                    Журнал
                                </NavLink>
                            </div>

                            {/* <HeaderCart headerMobail={true} /> */}

                            <HeaderModal isLogin={isLogin} />
                        </nav>

                        <div className="header-right">
                            {/* <HeaderCart headerMobail={false} /> */}

                            <>
                                {isLoaded ? (
                                    <>
                                        {isLogin ? (
                                            <div className="header-user">
                                                <div className="header-user__img-wrapper">
                                                    <div
                                                        style={{
                                                            backgroundImage: `url(${
                                                                user.avatar !==
                                                                "default"
                                                                    ? user.avatar
                                                                    : `${DOMEN}/public/storage/all/default_avatar.svg`
                                                            })`,
                                                        }}
                                                        className="header-user__img"
                                                    ></div>
                                                </div>
                                                <svg
                                                    width="15"
                                                    height="9"
                                                    viewBox="0 0 15 9"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="header-user__arrow"
                                                >
                                                    <line
                                                        x1="0.353553"
                                                        y1="0.646447"
                                                        x2="7.42462"
                                                        y2="7.71751"
                                                        stroke="#000"
                                                    />
                                                    <line
                                                        x1="6.64645"
                                                        y1="7.71752"
                                                        x2="13.7175"
                                                        y2="0.646454"
                                                        stroke="#000"
                                                    />
                                                </svg>
                                                <div className="header-user-menu">
                                                    <Link
                                                        to="/library"
                                                        className="header-user-menu__link"
                                                    >
                                                        <svg
                                                            width="20"
                                                            height="28"
                                                            viewBox="0 0 20 28"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M0.869566 24.7826C0.389294 24.7826 0 24.3932 0 23.913V3.58696C0 1.60908 1.60908 0 3.58696 0H19.1304C19.6107 0 20 0.389348 20 0.869566V20.8696C20 21.3498 19.6107 21.7392 19.1304 21.7392C18.6502 21.7392 18.2609 21.3498 18.2609 20.8696V1.73913H3.58696C2.56804 1.73913 1.73913 2.56804 1.73913 3.58696V23.913C1.73913 24.3932 1.34984 24.7826 0.869566 24.7826Z" />
                                                            <path d="M19.1304 27.826H3.91304C1.75538 27.826 0 26.0708 0 23.913C0 21.7554 1.75538 20 3.91304 20H19.1304C19.6107 20 20 20.3894 20 20.8696C20 21.3498 19.6107 21.7392 19.1304 21.7392H3.91304C2.71434 21.7392 1.73913 22.7144 1.73913 23.913C1.73913 25.1118 2.71434 26.087 3.91304 26.087H19.1304C19.6107 26.087 20 26.4764 20 26.9566C20 27.4368 19.6107 27.826 19.1304 27.826Z" />
                                                            <path d="M19.1309 24.7827H3.91353C3.43325 24.7827 3.04395 24.3933 3.04395 23.9131C3.04395 23.4329 3.43325 23.0435 3.91353 23.0435H19.1309C19.6112 23.0435 20.0004 23.4329 20.0004 23.9131C20.0004 24.3933 19.6112 24.7827 19.1309 24.7827Z" />
                                                            <path d="M4.78267 21.7392C4.30239 21.7392 3.91309 21.3498 3.91309 20.8696V0.869566C3.91309 0.389348 4.30239 0 4.78267 0C5.26293 0 5.65223 0.389348 5.65223 0.869566V20.8696C5.65223 21.3498 5.26293 21.7392 4.78267 21.7392Z" />
                                                        </svg>
                                                        Мои курсы
                                                    </Link>
                                                    <Link
                                                        to="/cabinet"
                                                        className="header-user-menu__link"
                                                    >
                                                        <svg
                                                            width="21"
                                                            height="21"
                                                            viewBox="0 0 21 21"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="header-user-menu__link__svg"
                                                        >
                                                            <path d="M10.6007 10.0343V0.578655C10.6007 0.25718 10.3436 0 10.0221 0C9.7006 0 9.44342 0.25718 9.44342 0.578655V10.0343C8.1018 10.3043 7.08594 11.4916 7.08594 12.9147C7.08594 14.3377 8.1018 15.5251 9.44342 15.7951V20.0043C9.44342 20.3257 9.7006 20.5829 10.0221 20.5829C10.3436 20.5829 10.6007 20.3257 10.6007 20.0043V15.7951C11.9424 15.5251 12.9582 14.3377 12.9582 12.9147C12.9582 11.4959 11.9466 10.3043 10.6007 10.0343ZM10.0221 14.6935C9.04051 14.6935 8.24324 13.8963 8.24324 12.9147C8.24324 11.9331 9.04051 11.1359 10.0221 11.1359C11.0036 11.1359 11.8009 11.9331 11.8009 12.9147C11.8009 13.8963 11.0036 14.6935 10.0221 14.6935Z" />
                                                            <path d="M3.51479 5.18217V0.578655C3.51479 0.25718 3.25761 0 2.93613 0C2.61465 0 2.35748 0.25718 2.35748 0.578655V5.18217C1.01586 5.45221 0 6.63952 0 8.06259C0 9.48564 1.01586 10.673 2.35748 10.943V20.0043C2.35748 20.3257 2.61465 20.5829 2.93613 20.5829C3.25761 20.5829 3.51479 20.3257 3.51479 20.0043V10.9387C4.85641 10.6687 5.87227 9.48136 5.87227 8.05829C5.87227 6.63524 4.85641 5.45221 3.51479 5.18217ZM2.93613 9.84141C1.95456 9.84141 1.15731 9.04415 1.15731 8.06259C1.15731 7.08101 1.95456 6.28376 2.93613 6.28376C3.91771 6.28376 4.71496 7.08101 4.71496 8.06259C4.71496 9.04415 3.91341 9.84141 2.93613 9.84141Z" />
                                                            <path d="M17.6437 5.18217V0.578655C17.6437 0.25718 17.3865 0 17.065 0C16.7436 0 16.4864 0.25718 16.4864 0.578655V5.18217C15.1448 5.45221 14.1289 6.63952 14.1289 8.06259C14.1289 9.48564 15.1448 10.673 16.4864 10.943V20.0085C16.4864 20.33 16.7436 20.5872 17.065 20.5872C17.3865 20.5872 17.6437 20.33 17.6437 20.0085V10.9387C18.9853 10.6687 20.0012 9.48136 20.0012 8.05829C20.0012 6.63524 18.9896 5.45221 17.6437 5.18217ZM17.065 9.84141C16.0834 9.84141 15.2862 9.04415 15.2862 8.06259C15.2862 7.08101 16.0834 6.28376 17.065 6.28376C18.0466 6.28376 18.8438 7.08101 18.8438 8.06259C18.8438 9.04415 18.0466 9.84141 17.065 9.84141Z" />
                                                        </svg>
                                                        Настройки
                                                    </Link>
                                                    <Link
                                                        to="/logout"
                                                        className="header-user-menu__link"
                                                    >
                                                        <svg
                                                            width="15"
                                                            height="15"
                                                            viewBox="0 0 15 15"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path d="M9.94548 8.01137H0.489124C0.219127 8.01137 0 7.78228 0 7.50001C0 7.21774 0.219127 6.98865 0.489124 6.98865H9.94548C10.2155 6.98865 10.4346 7.21774 10.4346 7.50001C10.4346 7.78228 10.2155 8.01137 9.94548 8.01137Z" />
                                                            <path d="M7.33666 10.7386C7.21139 10.7386 7.08625 10.6889 6.99095 10.5886C6.79989 10.3889 6.79989 10.065 6.99095 9.86516L9.25399 7.49937L6.99095 5.13345C6.79989 4.9337 6.79989 4.60973 6.99095 4.40998C7.18214 4.21022 7.4919 4.21022 7.68296 4.40998L10.2916 7.13732C10.4826 7.33707 10.4826 7.66091 10.2916 7.86066L7.68296 10.5879C7.58707 10.6889 7.46193 10.7386 7.33666 10.7386Z" />
                                                            <path d="M7.82627 15C4.85821 15 2.23788 13.1332 1.14942 10.2436C1.0503 9.98177 1.17413 9.68452 1.42526 9.5809C1.67568 9.47865 1.96072 9.60612 2.05984 9.87004C2.99963 12.3648 5.26327 13.9773 7.82627 13.9773C11.2423 13.9773 14.0219 11.0713 14.0219 7.5C14.0219 3.92871 11.2423 1.02272 7.82627 1.02272C5.26327 1.02272 2.99963 2.63521 2.05984 5.12996C1.96001 5.39388 1.67568 5.52135 1.42526 5.4191C1.17413 5.31548 1.0503 5.01823 1.14942 4.75643C2.23788 1.86679 4.85821 0 7.82627 0C11.7817 0 15.0001 3.36479 15.0001 7.5C15.0001 11.6352 11.7817 15 7.82627 15Z" />
                                                        </svg>
                                                        Выйти
                                                    </Link>
                                                </div>
                                            </div>
                                        ) : (
                                            <Link
                                                to="/login"
                                                className="header-login"
                                            >
                                                <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 20 20"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M13.2608 10.6522H0.652174C0.292173 10.6522 0 10.3601 0 10.0001C0 9.64007 0.292173 9.3479 0.652174 9.3479H13.2608C13.6208 9.3479 13.913 9.64007 13.913 10.0001C13.913 10.3601 13.6208 10.6522 13.2608 10.6522Z" />
                                                    <path d="M9.78287 14.1303C9.61585 14.1303 9.44899 14.0669 9.32193 13.9391C9.06717 13.6843 9.06717 13.2713 9.32193 13.0164L12.3393 9.9991L9.32193 6.98168C9.06717 6.72692 9.06717 6.31374 9.32193 6.05899C9.57684 5.80423 9.98986 5.80423 10.2446 6.05899L13.7228 9.53736C13.9776 9.79211 13.9776 10.2051 13.7228 10.4599L10.2446 13.9381C10.1168 14.0669 9.9499 14.1303 9.78287 14.1303Z" />
                                                    <path d="M10.4345 19.5654C6.47703 19.5654 2.98321 17.1845 1.5319 13.4992C1.39975 13.1653 1.56486 12.7862 1.89971 12.654C2.2336 12.5236 2.61366 12.6862 2.74581 13.0228C3.99889 16.2045 7.01711 18.261 10.4345 18.261C14.9892 18.261 18.6954 14.5548 18.6954 10.0001C18.6954 5.44538 14.9892 1.73916 10.4345 1.73916C7.01711 1.73916 3.99889 3.79568 2.74581 6.97741C2.6127 7.31401 2.2336 7.47657 1.89971 7.34617C1.56486 7.21402 1.39975 6.83491 1.5319 6.50102C2.98321 2.81566 6.47703 0.434814 10.4345 0.434814C15.7084 0.434814 19.9998 4.72617 19.9998 10.0001C19.9998 15.274 15.7084 19.5654 10.4345 19.5654Z" />
                                                </svg>

                                                <span className="header-login__text">
                                                    Войти
                                                </span>
                                            </Link>
                                        )}
                                    </>
                                ) : (
                                    <Link to="/login" className="header-login">
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path d="M13.2608 10.6522H0.652174C0.292173 10.6522 0 10.3601 0 10.0001C0 9.64007 0.292173 9.3479 0.652174 9.3479H13.2608C13.6208 9.3479 13.913 9.64007 13.913 10.0001C13.913 10.3601 13.6208 10.6522 13.2608 10.6522Z" />
                                            <path d="M9.78287 14.1303C9.61585 14.1303 9.44899 14.0669 9.32193 13.9391C9.06717 13.6843 9.06717 13.2713 9.32193 13.0164L12.3393 9.9991L9.32193 6.98168C9.06717 6.72692 9.06717 6.31374 9.32193 6.05899C9.57684 5.80423 9.98986 5.80423 10.2446 6.05899L13.7228 9.53736C13.9776 9.79211 13.9776 10.2051 13.7228 10.4599L10.2446 13.9381C10.1168 14.0669 9.9499 14.1303 9.78287 14.1303Z" />
                                            <path d="M10.4345 19.5654C6.47703 19.5654 2.98321 17.1845 1.5319 13.4992C1.39975 13.1653 1.56486 12.7862 1.89971 12.654C2.2336 12.5236 2.61366 12.6862 2.74581 13.0228C3.99889 16.2045 7.01711 18.261 10.4345 18.261C14.9892 18.261 18.6954 14.5548 18.6954 10.0001C18.6954 5.44538 14.9892 1.73916 10.4345 1.73916C7.01711 1.73916 3.99889 3.79568 2.74581 6.97741C2.6127 7.31401 2.2336 7.47657 1.89971 7.34617C1.56486 7.21402 1.39975 6.83491 1.5319 6.50102C2.98321 2.81566 6.47703 0.434814 10.4345 0.434814C15.7084 0.434814 19.9998 4.72617 19.9998 10.0001C19.9998 15.274 15.7084 19.5654 10.4345 19.5654Z" />
                                        </svg>

                                        <span className="header-login__text">
                                            Войти
                                        </span>
                                    </Link>
                                )}
                            </>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
});

export default Header;
